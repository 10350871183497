var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticClass: "fill-height pa-0 ma-0", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height pa-0 ma-0" },
        [
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$vuetify.breakpoint.mdAndUp,
                  expression: "$vuetify.breakpoint.mdAndUp",
                },
              ],
              staticClass: "pa-0 elevation-10",
              attrs: { cols: "6" },
            },
            [
              _c("image-panel", {
                attrs: {
                  src: "https://storage.googleapis.com/drumnow-apps_public/images/subscribe-landing.jpg",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass: "fill-height",
                  attrs: { justify: "start", align: "center" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "ma-5 transparent elevation-0",
                      attrs: { width: "400" },
                    },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c("logo"),
                          _c("h1", { staticClass: "overline mt-6" }, [
                            _vm._v("Confirm tier selection"),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-12 pa-6",
                              attrs: { justify: "space-between" },
                            },
                            [
                              _c("div", [
                                _c("p", { staticClass: "overline" }, [
                                  _vm._v("Selected Tier"),
                                ]),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v(_vm._s(_vm.parsedTier.name)),
                                ]),
                              ]),
                              _c("div", [
                                _c("p", { staticClass: "overline" }, [
                                  _vm._v(
                                    _vm._s(_vm.parsedTier.frequency) + " cost"
                                  ),
                                ]),
                                _c("h1", { staticClass: "title" }, [
                                  _vm._v("$" + _vm._s(_vm.parsedTier.price)),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        { staticClass: "mt-12" },
                        [
                          _vm.session
                            ? _c("stripe-checkout", {
                                ref: "sessionRef",
                                attrs: {
                                  pk: _vm.pk,
                                  "session-id": _vm.session,
                                  successUrl: _vm.successUrl,
                                  cancelUrl: _vm.cancelUrl,
                                },
                              })
                            : _vm._e(),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.loading,
                                block: "",
                                color: "primary",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.sessionRef.redirectToCheckout()
                                },
                              },
                            },
                            [_vm._v("Continue to checkout")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }